<div data-cy="menu-container" class="menu-container" [class.desktop]="(appService.isMobile$ | async) === false">
  @if ((appService.isMobile$ | async) === false) {
    <div class="header">
      <ion-menu-toggle data-cy="desktop-in-menu-toggle-btn">
        <img src="assets/icon/white-close.svg" alt="" />
      </ion-menu-toggle>
      <div>{{ 'PWA_Menu_header' | translate }}</div>
    </div>
  }

  <form [formGroup]="searchForm" class="search-container" [class.mobile]="appService.isMobile$ | async" (ngSubmit)="searchBook()">
    <ion-input data-cy="menu-search-input" formControlName="search" class="search" [placeholder]="'Search_searchBar_FindABook' | translate">
      <img src="assets/icon/search.svg" slot="start" />
    </ion-input>

    <app-button data-cy="menu-search-btn" (clicked)="searchBook()" [icon]="'assets/icon/right-arrow-bold.svg'" [shape]="'circle'" [size]="'small'" />
  </form>

  <div class="options">
    <div class="option" (click)="goToMyLibrary()" data-cy="menu-go-to-my-library-btn">
      {{ 'mainMenu_CTA_myLibrary' | translate }}
      <img src="assets/icon/big-arrow-right.svg" alt="" />
    </div>
    <div class="option" (click)="goToLanguage()" data-cy="menu-go-to-language">
      {{ 'PWA_Menu_link_appLanguage' | translate }}
      <app-button
        data-cy="menu-go-to-language-label"
        [color]="'blue'"
        [size]="'xxsmall'"
        [label]="(selectedLanguageName$ | async) || ''"
        [icon]="'assets/icon/globe.svg'" />
    </div>
    <div class="option" (click)="goToLibraryCode()">
      {{ 'PWA_Menu_link_libraryCode' | translate }}
      <img src="assets/icon/big-arrow-right.svg" alt="" />
    </div>
    <div class="option" (click)="notImplemented()">
      {{ 'PWA_Menu_link_notifications' | translate }}
      <img src="assets/icon/big-arrow-right.svg" alt="" />
    </div>
    <div class="option" (click)="howToUse()">
      {{ 'PWA_Menu_link_howToUseBooksmart' | translate }}
      <img src="assets/icon/big-arrow-right.svg" alt="" />
    </div>
    <div class="option" (click)="getHelp()">
      {{ 'PWA_Menu_link_getHelp' | translate }}
      <img src="assets/icon/big-arrow-right.svg" alt="" />
    </div>
    <div class="option" (click)="openTerms()">
      {{ 'PWA_Menu_link_TandC' | translate }}
      <img src="assets/icon/big-arrow-right.svg" alt="" />
    </div>

    @if ((userState.isMoya$ | async) === false) {
      @if (userState.isGuest$ | async) {
        <div class="option" (click)="login()">
          {{ 'PWA_LoginOrCreateAccount_header' | translate }}
          <img src="assets/icon/big-arrow-right.svg" alt="" />
        </div>
      } @else {
        <div class="option" (click)="logout()" data-cy="logout-btn">
          {{ 'PWA_Menu_link_logout' | translate }}
          <img src="assets/icon/logout.svg" alt="" />
        </div>
      }
    }
  </div>

  @if ((userState.isGuest$ | async) === false && (userState.isMoya$ | async) === false) {
    <div class="delete-account" data-cy="delete-account-btn" (click)="deleteAccount()">{{ 'PWA_Menu_link_deleteAccount' | translate }}</div>
  }

  <div class="powered-by" [class.mobile]="appService.isMobile$ | async">
    <img src="assets/logo/wr-powered-by.svg" alt="" />
  </div>

  <div class="app-version" [class.mobile]="appService.isMobile$ | async">
    {{ 'PWA_Menu_info_appVersion' | translate: { versionNumber } }}
    @if (env !== 'prod') {
      - {{ env }}
    }
  </div>
</div>
